<script setup lang="ts">
const { data: schedules, date } = useWorkSchedules();
</script>

<template>
  <div class="flex justify-end">
    <EditWorkSchedule :initialUser="null">
      <PrimeButton class="font-bold" size="small" rounded>
        <i class="pi pi-plus"></i>
        Dodaj grafik
      </PrimeButton>
    </EditWorkSchedule>
  </div>

  <InteractiveMonthCalendar v-model:current-date="date" />

  <div
    v-auto-animate
    class="mt-6 grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5"
  >
    <div
      :key="schedule.id"
      v-for="schedule in schedules"
      class="flex justify-between gap-1 rounded-xl border border-gray-iron-200 bg-gray-25 p-3"
    >
      <div class="space-y-2">
        <span class="block text-base font-medium text-gray-iron-900">
          {{ schedule.user.name }} {{ schedule.user.last_name }}
        </span>
        <span class="block text-sm text-gray-iron-500">
          {{ schedule.user.roles && schedule.user.roles[0].name }}
        </span>
        <div class="flex items-center gap-1.5 text-sm text-gray-iron-900">
          <i class="pi pi-clock"></i>
          <span>
            {{ schedule.start_time }} -
            {{ schedule.end_time }}
          </span>
        </div>
      </div>

      <EditWorkSchedule :initialUser="schedule.user.id">
        <PrimeButton
          class="max-h-8 max-w-8 bg-white"
          icon="pi pi-pen-to-square"
          rounded
          outlined
          severity="secondary"
          aria-label="Edytuj grafik"
        />
      </EditWorkSchedule>
    </div>
  </div>
</template>
