<script setup lang="ts">
definePageMeta({
  layout: 'authorized',
  middleware: ['auth'],
  title: 'Personel',
});

useHead({
  title: 'Personel',
});
</script>

<template>
  <PrimeCard>
    <template #content>
      <PrimeTabs value="0" lazy scrollable>
        <PrimeTabList class="w-full">
          <PrimeTab class="flex-1" value="0">Lista personelu</PrimeTab>
          <PrimeTab class="flex-1" value="1">Grafik</PrimeTab>
        </PrimeTabList>

        <PrimeTabPanels>
          <PrimeTabPanel value="0" class="py-4">
            <PersonelTable />
          </PrimeTabPanel>

          <PrimeTabPanel value="1" class="py-4">
            <WorkSchedules />
          </PrimeTabPanel>
        </PrimeTabPanels>
      </PrimeTabs>
    </template>
  </PrimeCard>
</template>
