<script setup lang="ts">
const visible = ref(false);

withDefaults(
  defineProps<{
    initialUser?: number | null;
  }>(),
  {
    initialUser: null,
  },
);
</script>

<template>
  <div @click="visible = true">
    <slot></slot>
  </div>

  <PrimeDialog
    v-model:visible="visible"
    modal
    header="Edytuj grafik"
    :style="{ width: '576px', 'max-width': '90%' }"
    :closeButtonProps="{
      text: true,
      severity: 'secondary',
      style: 'box-shadow: none; padding: 0;',
    }"
  >
    <LazyWorkScheduleEditForm
      v-if="visible"
      :initialUser="initialUser"
      v-model:visible="visible"
    >
      <template #cancel>
        <PrimeButton
          class="font-bold"
          size="small"
          outlined
          rounded
          severity="secondary"
          @click="visible = false"
        >
          Anuluj
        </PrimeButton>
      </template>
    </LazyWorkScheduleEditForm>
  </PrimeDialog>
</template>
