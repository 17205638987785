<script setup lang="ts">
const { searchedEmployees: employees, isLoading, search } = useAllEmployees();
</script>

<template>
  <div class="space-y-6">
    <PrimeIconField>
      <PrimeInputIcon class="pi pi-search" />
      <PrimeInputText v-model="search" placeholder="Szukaj" />
    </PrimeIconField>

    <template v-if="employees.length || isLoading">
      <PrimeDataTable
        class="primetable"
        :value="employees"
        tableStyle="min-width: 50rem"
      >
        <PrimeColumn field="full_name" header="Imię i nazwisko" />
        <PrimeColumn field="email" header="Adres e-mail"></PrimeColumn>
        <PrimeColumn field="roles" header="Rola">
          <template #body="{ data }: { data: Personel }">
            {{ data.roles?.map((role) => getRole(role.name)).join(', ') }}
          </template>
        </PrimeColumn>

        <PrimeColumn field="coordinator" header="Jest koordynatorem">
          <template #body="{ data }: { data: Personel }">
            <div class="flex items-center gap-2">
              <i
                :class="{
                  'pi pi-check-circle text-success-600': isCoordinator(data),
                  'pi pi-times-circle text-red-600': !isCoordinator(data),
                }"
              />
              <span class="text-sm">
                {{ isCoordinator(data) ? 'Tak' : 'Nie' }}
              </span>
            </div>
          </template>
        </PrimeColumn>
      </PrimeDataTable>
    </template>

    <div v-else class="text-sm text-gray-iron-900">Brak wyników</div>
  </div>
</template>
