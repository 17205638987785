import { fetcher } from '../fetcher';

const WORK_SCHEDULES_ENDPOINT = '/work-schedules';

export const workSchedulesService = {
  getSchedules: async (date: string) => {
    return await fetcher(`${WORK_SCHEDULES_ENDPOINT}?date=${date}`, {
      method: 'GET',
      schema: workSchedulesSchema,
    });
  },

  storeSchedule: async (payload: WorkScheduleForm) => {
    return await fetcher(`${WORK_SCHEDULES_ENDPOINT}`, {
      method: 'POST',
      body: payload,
      // schema: medicsSchema,
    });
  },

  getUserSchedules: async (userId: number) => {
    return await fetcher(`${WORK_SCHEDULES_ENDPOINT}/users/${userId}`, {
      method: 'GET',
      schema: userWorkScheduleSchema,
    });
  },
};
