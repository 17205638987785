import { useQuery } from '@tanstack/vue-query';
import { workSchedulesService } from '~/utils/api/workSchedulesService';

export function useWorkSchedules() {
  const date = ref(new Date().toISOString());

  const { data, isLoading } = useQuery({
    queryKey: ['work-schedules', date],
    queryFn: () =>
      workSchedulesService.getSchedules(
        formatDateToYYYYMMDD(new Date(date.value)),
      ),
  });

  return { data, date, isLoading };
}
